import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 148px;
    left: 0;
    right: 0;
    background: ${props => props.theme.colors.gray1};
    z-index: 9999;
    overflow-y: auto;
`
const Elem = styled.div`
    padding: 20px;
    border-bottom: solid 1px gray;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 900;
    font-size: 1.2em;
`

const SubNav = ({ edges, activeIndex, setActiveIndex, setShowNav }) => {

    const onClicked = (index) => {
        setActiveIndex(index);
        setShowNav();
    }

    const renderLinks = () => {
        const render = [];
        console.log(edges);
        edges.forEach((edge, index) => {
            if (index !== activeIndex) {
                render.push(
                    <Elem 
                        active={activeIndex === index}
                        onClick={() => onClicked(index)}
                    >
                        {edge.node.childMarkdownRemark.frontmatter.title}
                    </Elem>
                )
            }
        })
        return render;
    }
    
    return (
        <Wrapper className="animate__animated animate__fadeInDown">
            { renderLinks() }
        </Wrapper>
    )
};

export default SubNav;