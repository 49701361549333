import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-modal-routing-3';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { UserCircle, Time, Map } from 'styled-icons/boxicons-regular/';

const Outer = styled.section`
    width: 100%;
    padding: 10px;
    display: flex;
    max-width: 400px;
`;
const Wrapper = styled(Link)`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 15px;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkGray};
`;
const Container = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: -7px;
`;
const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
`;
const OverlayInner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    max-width: 96%;
    color: ${(props) => props.theme.colors.white};
    font-size: 1.5em;
`;

const Content = styled.div`
    padding: 20px;
    border-right: solid 1px ${(props) => props.theme.colors.gray2};
    border-left: solid 1px ${(props) => props.theme.colors.gray2};
    border-bottom: solid 1px ${(props) => props.theme.colors.gray2};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Elem = styled.p`
    display: flex;
    align-items: center;
`;

const Ico = styled.div`
    color: ${(props) => props.theme.colors.darkGray};
    margin-right: 10px;
`;
/*

    kontakt={sub.kontaktperson} 
    ort={sub.ort} 
    beschreibung={sub.beschreibung}

*/

const Tile = ({ children, image, ort, kontakt, time }) => {
    const slug = children
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

    return (
        <Outer>
            <Wrapper to={slug} asModal state={{ modal: true, noScroll: true }}>
                <Container>
                    <GatsbyImage image={getImage(image)} alt={children} />
                    <Overlay>
                        <OverlayInner>
                            <Title>{children}</Title>
                        </OverlayInner>
                    </Overlay>
                </Container>
                <Content>
                    <Elem>
                        <Ico>
                            <UserCircle size={30} />
                        </Ico>{' '}
                        {kontakt}
                    </Elem>
                    <Elem>
                        <Ico>
                            <Time size={30} />
                        </Ico>{' '}
                        {time}
                    </Elem>
                    <Elem>
                        <Ico>
                            <Map size={30} />
                        </Ico>{' '}
                        {ort}
                    </Elem>
                </Content>
            </Wrapper>
        </Outer>
    );
};

export default Tile;
