import React, { useState } from 'react';
import styled from 'styled-components';
import SubNav from './subNav';
import MobileSubNav from './mobileSubNavController';
import Tile from './tile';
import Content from '../content';

const Wrapper = styled.div``;
const TileWrapper = styled.div`
    padding: 20px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    @media (max-width: 1100px) {
        grid-template-columns: auto auto;
    }
    @media (max-width: 650px) {
        grid-template-columns: auto;
    }
`;

const Abteilung = ({ edges }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const renderTiles = () => {
        const render = [];
        edges[
            activeIndex
        ].node.childMarkdownRemark.frontmatter.mannschaften.forEach((sub) => {
            render.push(
                <Tile
                    image={sub.bild}
                    kontakt={sub.kontaktperson}
                    ort={sub.ort}
                    beschreibung={sub.beschreibung}
                    time={sub.trainingszeiten}
                >
                    {sub.title}
                </Tile>
            );
        });
        return render;
    };

    return (
        <Wrapper>
            <SubNav
                edges={edges}
                activeIndex={activeIndex}
                setActiveIndex={(e) => setActiveIndex(e)}
            />
            <MobileSubNav
                edges={edges}
                activeIndex={activeIndex}
                setActiveIndex={(e) => setActiveIndex(e)}
            />
            <TileWrapper>{renderTiles()}</TileWrapper>
            {edges[activeIndex].node.childMarkdownRemark.html && (
                <Content>
                    {edges[activeIndex].node.childMarkdownRemark.html}
                </Content>
            )}
        </Wrapper>
    );
};

export default Abteilung;
