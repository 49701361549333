import * as React from "react";
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Abteilung from '../components/abteilung';

const IndexPage = (props) => {
    const edges = props.data.allFile.edges;

    return (
        <SiteWrapper title="Kinderturnen - SV Kappel" description="Bewegung ist mehr als „sich nur bewegen“, sie ist wichtiger Motor für die gesamte kindliche Entwicklung.">
            <Abteilung edges={edges} />
        </SiteWrapper>
    )
}

export default IndexPage;

export const query = graphql`
    query KinderturnenQuery {
        allFile(filter: {sourceInstanceName: {eq: "kinderturnen"}}) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                    frontmatter {
                        title
                        mannschaften {
                        beschreibung
                        bild {
                            childImageSharp {
                                gatsbyImageData(width: 400, aspectRatio: 1.833)
                            }
                        }
                        kontaktperson
                        ort
                        title
                        trainingszeiten
                        }
                    }
                    fields {
                        slug
                    }
                    }
                }
            }
        }
    }
`
