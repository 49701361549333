import React, { useState } from 'react';
import styled from 'styled-components';
import { CaretDown, CaretUp } from 'styled-icons/boxicons-regular/';
import MobileSubNav from './mobileSubNav';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (min-width: 980px) {
        display: none;
    }
`
const ActiveWrapper = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.accent};
    padding: 10px 20px;
    color: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`
const ActiveTitle = styled.p`
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    font-size: 2em;
    margin: 0;
    padding: 0;
`
const Button = styled.div`

`

const SubNav = ({ edges, activeIndex, setActiveIndex }) => {

    const [showNav, setShowNav] = useState(false);
    
    return (
        <Wrapper>
            <ActiveWrapper onClick={() => setShowNav(!showNav)}>
                <ActiveTitle>
                    {edges[activeIndex].node.childMarkdownRemark.frontmatter.title}
                </ActiveTitle>
                <Button>
                    { showNav ?
                        <CaretUp size={40} color="#fff" /> :
                        <CaretDown size={40} color="ffff" />
                    }
                </Button>
            </ActiveWrapper>
            { showNav && <MobileSubNav setShowNav={() => setShowNav(!showNav)} edges={edges} activeIndex={activeIndex} setActiveIndex={setActiveIndex} /> }
        </Wrapper>
    )
};

export default SubNav;