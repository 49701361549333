import React from 'react';
import styled from 'styled-components';
import Elem from '../common/subNavElem';

const Wrapper = styled.div`
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: row;
    @media (max-width: 980px) {
        display: none;
    }
`


const SubNav = ({ edges, activeIndex, setActiveIndex }) => {
    
    const renderLinks = () => {
        const render = [];
        edges.forEach((edge, index) => {
            render.push(
                <Elem 
                    active={activeIndex === index}
                    onClick={() => setActiveIndex(index)}
                >
                    {edge.node.childMarkdownRemark.frontmatter.title}
                </Elem>
            )
        })
        return render;
    }
    
    return (
        <Wrapper>
            { renderLinks() }
        </Wrapper>
    )
};

export default SubNav;